import { projectFunctions } from "../firebase/config";
import { ref } from "vue";

export default () => {
  const pending = ref(false);
  const error = ref(null);

  const call = async (name, data = null) => {
    const result = ref(null);

    try {
      pending.value = true;
      const res = await projectFunctions.httpsCallable(name)(data);
      result.value = res.data;
    } catch (e) {
      error.value = e.message;
    } finally {
      pending.value = false;
    }

    return { result };
  };

  return { call, pending, error };
};
