<template>
  <Pending v-if="pending">Chargement en cours...</Pending>
  <PageLayout v-else>
    <template #header>
      <h1 class="display-3 text-center">
        Inventaire du {{ format(parseJSON(board.period), "dd/MM/yyyy") }}
      </h1>
    </template>
    <template #default>
      <div class="gap-2 p-3">
        <Card v-if="board.status !== 'sent' && board.status !== 'validated'">
          <template #title>
            <h4>Progression</h4>
          </template>
          <template #content>
            <ProgressBar :progress="boardStatus[board.status].progress">{{
              boardStatus[board.status].progressLabel
            }}</ProgressBar>
            <div class="p-field p-fluid mt-2">
              <label>Commentaires</label>
              <Textarea
                id="comments"
                v-model="board.comments"
                rows="5"
                autoResize
                @change="onInput"
              ></Textarea>
            </div>
          </template>
          <template #footer>
            <div class="flex flex-column">
              <Button
                :label="
                  user.roles?.moderator
                    ? 'Voir l\'inventaire'
                    : boardStatus[board.status].label
                "
                :icon="
                  user.roles?.moderator
                    ? 'pi pi-eye'
                    : boardStatus[board.status].icon
                "
                iconPos="right"
                :class="`${boardStatus[board.status].class}`"
                @click="onNavigate"
                v-if="board.status !== 'validated' && board.status !== 'sent'"
              />
            </div>
          </template>
        </Card>
        <Card
          class="mt-2"
          v-if="board.status === 'validated' || board.status === 'sent'"
        >
          <template #title>
            <div
              v-if="board.status === 'validated'"
              class="flex justify-content-between"
            >
              <span class="text-success">Inventaire validé</span>
              <Button
                class="p-button-sm p-button-secondary p-button-outlined"
                label="Modifier"
                @click="onUnvalidate"
                v-can:unvalidateBoards="user.role"
              ></Button>
              <!--              <Button-->
              <!--                class="p-button-sm p-button-secondary p-button-outlined"-->
              <!--                label="Modifier"-->
              <!--                @click="onUnvalidate"-->
              <!--                v-roles="['moderator', 'admin']"-->
              <!--              ></Button>-->
            </div>
            <span class="text-primary" v-if="board.status === 'sent'"
              >Inventaire envoyé</span
            >
          </template>
          <template #subtitle>
            <span v-if="board.status === 'validated'"
              >le {{ format(parseJSON(board.validatedAt), "dd/MM/yyyy") }} à
              {{ format(parseJSON(board.validatedAt), "HH:mm") }}</span
            >
            <span v-if="board.status === 'sent'"
              >le {{ format(parseJSON(board.sentAt), "dd/MM/yyyy") }} à
              {{ format(parseJSON(board.sentAt), "HH:mm") }}</span
            >
          </template>
          <template #content>
            <ProgressBar :progress="boardStatus[board.status].progress">{{
              boardStatus[board.status].progressLabel
            }}</ProgressBar>
            <div class="p-field p-fluid mt-2">
              <label>Commentaires</label>
              <Textarea
                id="comments"
                v-model="board.comments"
                rows="5"
                autoResize
                @change="onInput"
                disabled
                placeholder="Aucun commentaire"
              ></Textarea>
            </div>
            <template v-if="board.status === 'sent'">
              <hr />
              <div
                v-for="(category, name) in sortedRows"
                :key="name"
                class="mb-2 p-field"
              >
                <label class="font-bold">{{ categories[name].name }}</label>
                <div
                  v-for="row in category"
                  :key="row.id"
                  class="flex justify-content-between text-xs"
                >
                  <span>{{ row.name }} {{ row.capacity * 100 }}cl</span>
                  <span
                    :class="
                      board.status === 'validated'
                        ? 'text-success'
                        : 'text-primary'
                    "
                    >{{ row.total }}</span
                  >
                </div>
              </div>
            </template>
          </template>
        </Card>
      </div>
    </template>
    <template #footer>
      <ActionsNav>
        <router-link :to="{ name: 'Boards' }">
          <BaseButtonSet
            label="Retour"
            color="secondary"
            icon="fa fa-arrow-left"
            text
            rtl
          ></BaseButtonSet>
        </router-link>
        <BaseButtonSet
          v-if="board.status === 'inprogress'"
          label="Valider l'inventaire"
          color="success"
          icon="fa fa-check"
          @click="onValidate"
          v-can:validateBoards="user.role"
        ></BaseButtonSet>
        <BaseButtonSet
          v-if="board.status === 'validated'"
          v-roles="['moderator', 'admin']"
          label="Envoyer l'inventaire"
          color="primary"
          icon="fa fa-paper-plane"
          @click="onSend"
          v-can:sendBoards="user.role"
        ></BaseButtonSet>
      </ActionsNav>
    </template>
  </PageLayout>
</template>

<script>
import { ref, computed, defineComponent } from "vue";
import { useRoute, useRouter } from "vue-router";
import useDatabase from "../../composables/useDatabase";
import useFunction from "../../composables/useFunctions";
import { groupBy, pickBy } from "lodash";
import { projectDatabase } from "../../firebase/config";
import { useConfirm } from "primevue/useconfirm";
import { format, parseJSON } from "date-fns";

import ProgressBar from "../../components/ProgressBar";
import { getUser } from "../../composables/getUser";
import useNetwork from "../../utils/useNetwork";
import useMercurial from "../../composables/useMercurial";

export default defineComponent({
  components: { ProgressBar },
  setup: function () {
    const { isOnline } = useNetwork();
    const route = useRoute();
    const router = useRouter();
    const confirm = useConfirm();
    const { user } = getUser();
    const { mercurialFolder } = useMercurial();
    const { centerId, boardId } = route.params;

    const { doc: center } = useDatabase("centers").getOne(centerId);

    const { docs: categories, pending: categoriesPending } = useDatabase(
      `${mercurialFolder.value}/categories`
    ).mapAll();

    const boardStatus = ref({
      new: {
        label: "Commencer l'inventaire",
        progressLabel: "A faire",
        progress: 3,
        class: "p-button-success",
      },
      inprogress: {
        label: "Continuer la saisie",
        progress: 40,
        progressLabel: "En cours de saisie",
        icon: "pi pi-pencil",
        class: "p-button-secondary",
      },
      validated: {
        label: "Inventaire validé",
        progress: 90,
        progressLabel: "En attente d'envoi",
        class: "p-button-success p-button-text",
      },
      sent: {
        label: "Inventaire archivé",
        progress: 100,
        progressLabel: "Terminé",
        class: "p-button-primary p-button-text",
      },
    });

    const { getOne, updateOne } = useDatabase(`boards`);
    const {
      doc: board,
      error: boardError,
      pending: boardPending,
    } = getOne(boardId);

    const sortedRows = computed(() => {
      let array = [];
      Object.entries(articles.value).map((a) => {
        array.push({
          id: a[0],
          total: getTotal(board.value.rows[a[0]]),
          ...a[1],
        });
      });
      const pickArray = Object.values(
        pickBy(array, (a) => Object.keys(board.value.rows).includes(a.id))
      );
      return groupBy(pickArray, "category");
    });
    const getTotal = (obj) => {
      if (obj) {
        let array = [];
        Object.entries(obj).map((val) => {
          array.push(val[1]);
        });
        return array.reduce((acc, current) => acc + current);
      } else {
        return null;
      }
    };

    const { mapAll } = useDatabase(`${mercurialFolder.value}/articles`);
    const { docs: articles, pending: articlesPending } = mapAll();

    const onInput = async () => {
      try {
        await updateOne(boardId, { comments: board.value.comments });
        console.log("Comments successfully updated");
        // TODO: toast
      } catch (e) {
        boardError.value = e.message;
        console.log(e);
      }
    };

    const onValidate = () => {
      confirm.require({
        message: "Êtes-vous certain de vouloir valider l'inventaire?",
        header: "Valider l'inventaire",
        icon: "pi pi-info-triangle",
        acceptClass: "p-button-success",
        acceptLabel: "Valider",
        rejectClass: "p-button-text p-button-secondary",
        rejectLabel: "Plus tard",
        accept: async () => {
          await projectDatabase.ref(`boards/${boardId}`).update({
            status: "validated",
            validatedAt: new Date().toISOString(),
          });
        },
      });
    };

    const onUnvalidate = () => {
      confirm.require({
        message: "Souhaitez-vous à nouveau ouvrir la saisie de l'inventaire?",
        header: "Modifier l'inventaire",
        icon: "pi pi-info-triangle",
        acceptClass: "p-button-warning",
        acceptLabel: "Modifier",
        rejectClass: "p-button-text p-button-secondary",
        rejectLabel: "Retour",
        accept: async () => {
          await projectDatabase.ref(`boards/${boardId}`).update({
            status: "inprogress",
          });
        },
      });
    };

    const { call, pending: onCallPending } = useFunction();
    const data = { boardId, centerId };
    const onSend = async () => {
      confirm.require({
        header: "Envoyer l'inventaire",
        message: isOnline.value
          ? "Attention, cette action est irreversible"
          : "Vous devez être connecté à internet pour envoyer l'inventaire",
        acceptClass: isOnline.value ? "p-button-primary" : "hidden",
        acceptLabel: "Envoyer",
        rejectClass: "p-button-text p-button-secondary",
        rejectLabel: isOnline.value ? "Plus tard" : "Retour",
        accept: async () => {
          await call("sendBoard", data);
        },
      });
    };

    const onNavigate = async () => {
      if (board.value.status === "new") {
        await projectDatabase.ref(`boards/${boardId}`).update({
          status: "inprogress",
          validatedAt: new Date().toISOString(),
        });
      }
      await router.push({
        name: "ZonesList",
        query: { board: boardId },
        props: { board: true },
      });
    };

    const pending = computed(
      () =>
        !!(
          articlesPending.value ||
          boardPending.value ||
          categoriesPending.value ||
          onCallPending.value
        )
    );

    return {
      user,
      board,
      center,
      categories,
      sortedRows,
      pending,
      articles,
      onInput,
      getTotal,
      onValidate,
      boardStatus,
      onNavigate,
      onSend,
      format,
      parseJSON,
      onUnvalidate,
    };
  },
});
</script>

<style lang="scss" scoped></style>
