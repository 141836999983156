<template>
  <div class="progress-container">
    <div class="progress-bar" :style="`width: ${progress}%`">
      <span class="progress-label" :class="progress <= 40 ? 'left' : 'right'"
        ><slot></slot
      ></span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    progress: {
      type: Number,
      default: 25,
    },
  },
  setup() {},
};
</script>

<style lang="scss" scoped>
.progress-container {
  border: solid 1px #f0f0f0;
  height: 20px;
  width: 100%;
  border-radius: 10px;
  margin: 1rem 0;
  position: relative;
  //overflow: hidden;
  .progress-bar {
    position: absolute;
    background-image: linear-gradient(45deg, $primary-dark, $primary);
    background-color: $primary;
    height: 100%;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
    .progress-label {
      position: absolute;
      font-size: 0.75rem;
      bottom: 0;
      white-space: nowrap;
      &.left {
        right: 2px;
        padding-left: 0.25rem;
        transform: translateX(100%) translateY(100%);
        border-left: solid 2px $primary;
      }
      &.right {
        right: 0;
        padding-right: 0.25rem;
        transform: translateY(100%);
        border-right: solid 2px $primary;
      }
    }
  }
}
</style>
